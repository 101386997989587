body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-picker-cell.ant-picker-cell-in-view:not(
    .ant-picker-cell-disabled,
    .ant-picker-cell-in-range,
    .ant-picker-cell-range-start,
    .ant-picker-cell-range-end
  ):has(div.compareInRage)::before {
  background-color: #fad7d7 !important;
  content: "";
}

.ant-picker-cell-in-view:not(
    .ant-picker-cell-disabled,
    .ant-picker-cell-in-range,
    .ant-picker-cell-range-start,
    .ant-picker-cell-range-end
  ):has(div.compareEnd)::before {
  background-color: #fcbdbd;
  inset-inline-end: 50%;
}

.ant-picker-cell-in-view:not(
    .ant-picker-cell-disabled,
    .ant-picker-cell-in-range,
    .ant-picker-cell-range-start,
    .ant-picker-cell-range-end
  ):has(div.compareStart)::before {
  background-color: #fcbdbd;
  inset-inline-start: 50%;
}

.ant-picker-cell-in-view:not(
    .ant-picker-cell-disabled,
    .ant-picker-cell-in-range,
    .ant-picker-cell-range-start,
    .ant-picker-cell-range-end
  )
  .compareStart {
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  color: #fff;
  background: #fcbdbd;
}

.ant-picker-cell-in-view:not(
    .ant-picker-cell-disabled,
    .ant-picker-cell-in-range,
    .ant-picker-cell-range-start,
    .ant-picker-cell-range-end
  )
  .compareEnd {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
  border-start-end-radius: 4px;
  border-end-end-radius: 4px;
  color: #fff;
  background: #fcbdbd;
}

.ant-picker-cell-in-view:not(
    .ant-picker-cell-disabled,
    .ant-picker-cell-in-range,
    .ant-picker-cell-range-start,
    .ant-picker-cell-range-end
  )
  .compareEnd.compareStart {
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
  border-start-end-radius: 4px;
  border-end-end-radius: 4px;
}
